import React from "react";
import {useRelatedStore} from "./Related.store";
import {format} from "date-fns";

export const RelatedArticles = () => {
    const {related} = useRelatedStore();
    const base = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/file/image/`;
    const displayIndexes = Array.from({ length: related?.length || 0 }, (_, i) => i).filter(i => i % 3 === 0);

    return (
        <>
            {related?.map((article, index) => (
                <div key={index}
                     className="card mb-3">
                    <div className="card-body">
                        {displayIndexes.includes(index) && article.featureImage?.id &&(<img src={base + article.featureImage?.id} className="card-img-top"
                              alt="..."/>)}
                        <span
                            className="small fw-light pe-1">{format(new Date(article.createdAt), "dd MMM yyyy")}</span>
                        <a href={article.permalink} target="_parent" className="card-link">
                            {article.title}
                        </a>
                    </div>
                </div>
            ))}
        </>
    )
}