import {create} from "zustand";
import {persist} from "zustand/middleware";
import {client} from "../../common/AxiosService";

export const useRelatedStore = create()(
    persist(
        (set, get) => ({
            related: [],
            loading: true,
            fetch: async (permalink) => {
                const resp = await client.get("/api/visitor/article/" + permalink + "/related");
                if (resp.status === 200) {
                    set({related: resp.data});
                }
                set({loading: false});
            }
        }),
        {
            name: "related-storage",
            whitelist: ["related"],
        }
    )
)

