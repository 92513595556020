import React from 'react';
import {createRoot} from "react-dom/client";
import {InfiniteArticles} from "./InfiniteArticles";
import {RelatedContainer} from "./related/Related.container";

const element = document.getElementById('voces-infinite-articles');
const related = document.getElementById('voces-related-articles');
if (element) {
    const infinite = createRoot(element)
    infinite.render(<InfiniteArticles/>)
}
if (related) {
    const relatedArticles = createRoot(related)
    relatedArticles.render(<RelatedContainer/>)
}
