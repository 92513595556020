import React, {useEffect} from 'react';
import {useRelatedStore} from "./Related.store";
import {RelatedArticles} from "./Related.articles";

export const RelatedContainer = () => {
    const {fetch, loading} = useRelatedStore();

    useEffect(() => {
        const permalink = document.getElementById('ppermalink');
        if (permalink) {
            fetch(permalink.attributes.value.nodeValue);
        }
    }, [fetch]);

    return (
        <>
            <h2>Lee también</h2>
            {!loading && <RelatedArticles/>}
        </>
    )
}