import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import {Spinner} from "../backoffice/components/Spinner";

export const InfiniteArticles = () => {
    const [items, setItems] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [hasMore, setHasMore] = React.useState(true);
    const [page, setPage] = React.useState(1);

    const fetchData = async () => {
        setIsLoading(true);
        setError(null);

        try {
            let url = `${window.location.origin}/article?page=${page}&size=10`;
            if (window.location.pathname.includes('categoria')) {
                url += `&category=${window.location.pathname.split('/')[2]}`;
            } else if (window.location.pathname.includes('etiqueta')) {
                url += `&tag=${window.location.pathname.split('/')[2]}`;
            }
            setPage(prevPage => prevPage + 1);
            const response = await fetch(url);
            const data = await response.json();
            setItems(prevItems => [...prevItems, ...data.content]);
            if (data.totalPages <= page) {
                setHasMore(false)
            }
        } catch (error) {
            setPage(prevPage => prevPage - 1);
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <InfiniteScroll
                dataLength={items.length}
                next={fetchData}
                hasMore={hasMore}
                loader={<Spinner/>}
                endMessage={<p></p>}
            >
                {items.map((article, index) =>
                    <section className="mb-3 p-3 border-top" key={index}>
                        <p className="fs-5">
                            <div className="row">
                                <div className="col">
                                    {article.category &&
                                        <a className="link-secondary vcs-link-categoria fw-medium"
                                           href={'/categoria/' + article?.category?.slug}>
                                            {article?.category?.name}
                                        </a>}
                                </div>
                                <div className="col text-end">

                                </div>
                            </div>
                            <h2>
                                <a className="link-dark vcs-link-titulo fw-bold"
                                   href={'/' + article.permalink}>{article.title}</a>
                            </h2>
                        </p>
                        <a className="link-underline-light"
                           href={'/' + article.permalink}>
                            <p className="text-muted" dangerouslySetInnerHTML={{__html: article.summary}}></p>
                            {article?.featureImage &&
                                <img src={'/file/image/' + article?.featureImage?.id}
                                     alt={article?.featureImage?.alt}
                                     className="img-fluid vw-100 rounded"/>
                            }
                        </a>
                        <Tags tag={article?.tags}/>
                    </section>
                )}
            </InfiniteScroll>
            {
                error && <p>Error: {error.message}</p>
            }
        </div>
    );
}

const Tags = ({tags}) => {
    const getTags = () => {
        try {
            return tags?.map(tag => <a className='btn btn-light btn-sm me-1' href={'/etiqueta/' + tag}>>
                {tag.toLowerCase()}
            </a>)
        } catch
            (err) {
            console.log(err)
            return ''
        }
    }
    return (
        <div className="my-1">
            {getTags}
        </div>)
}